import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ContactBtnComponent } from "../../Core/Components/contact-btn/contact-btn.component";

@Component({
  selector: "app-about",
  standalone: true,
  imports: [TranslateModule, ContactBtnComponent],
  templateUrl: "./about.component.html",
  styleUrl: "./about.component.scss",
})
export class AboutComponent {}
