<div class="container my-3">
  <h2 class="text-center">{{ translate ? (title | translate) : title }}</h2>
  <p>{{ translate ? (message | translate) : message }}</p>
  <div class="row mx-3 gap-2">
    <button class="btn btn-outline-primary col" (click)="confirm()">
      {{ translate ? (confirmText | translate) : confirmText }}
    </button>
    <button class="btn btn-outline-danger col" (click)="decline()">
      {{ translate ? (declineText | translate) : declineText }}
    </button>
  </div>
</div>
