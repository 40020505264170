<div class="number-select input-group">
  <span class="input-group-text fw-bold fs-5"
    ><i class="bi bi-info-circle" [ngbTooltip]="tooltip"></i
  ></span>
  <input
    class="form-control input-extra"
    [attr.data-cartname]="cartName"
    type="number"
    [value]="0"
    [min]="0"
    [max]="max"
    [step]="step"
    [name]="name"
    (change)="onInputChange($event)"
  />
  <span class="input-group-text">{{ me }}</span>
</div>

<ng-template #tooltip>
  <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(info)"></div
></ng-template>
