import { Component, EventEmitter, Input, Output, input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-confirm-dialog",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./confirm-dialog.component.html",
  styleUrl: "./confirm-dialog.component.scss",
})
export class ConfirmDialogComponent {
  constructor(private activeModal: NgbActiveModal) {}
  @Input() title: string = "";
  @Input() message: string = "";
  @Input() confirmText: string = "Yes";
  @Input() declineText: string = "No";
  @Input() translate: boolean = false;
  confirm() {
    this.activeModal.close(true);
  }
  decline() {
    this.activeModal.close(false);
  }
}
