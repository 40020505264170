import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ContactBtnComponent } from "../../Core/Components/contact-btn/contact-btn.component";

@Component({
  selector: "app-automatization",
  standalone: true,
  imports: [TranslateModule, CommonModule, ContactBtnComponent],
  templateUrl: "./automatization.component.html",
  styleUrl: "./automatization.component.scss",
})
export class AutomatizationComponent {}
